import React, { useEffect, useRef } from "react";
import styled from "styled-components";

// Updated 24 Testimonials
const testimonials = [
    {
        id: 1,
        quote:
            "DrillUp’s empathy-driven training made cross-cultural collaboration effortless. I felt like part of the team in no time!",
        avatar: "images/person1.webp",
        name: "Lina S.",
        role: "Software Engineer, Consultant",
    },
    {
        id: 2,
        quote:
            "I needed to brush up on my soft skills. DrillUp’s coaching accelerated my onboarding and boosted my confidence.",
        avatar: "images/person2.webp",
        name: "Darius W.",
        role: "Data Analyst, Contractor",
    },
    {
        id: 3,
        quote:
            "Their quick-integration approach helped me lead a brand-new product team seamlessly—regardless of everyone’s contract type.",
        avatar: "images/person3.webp",
        name: "Robert H.",
        role: "Senior Developer, Full-Time",
    },
    {
        id: 4,
        quote:
            "Navigating a multicultural environment was daunting, but DrillUp gave me the skillset to thrive from day one.",
        avatar: "images/person4.webp",
        name: "Isabella M.",
        role: "Front-End Engineer, Consultant",
    },
    {
        id: 5,
        quote:
            "I saw immediate results: less friction in meetings and faster decision-making thanks to their empathy-first training.",
        avatar: "images/person5.webp",
        name: "Kai L.",
        role: "Data Scientist, Full-Time",
    },
    {
        id: 6,
        quote:
            "DrillUp’s coaching tackled both cultural nuances and leadership growth. My team collaboration improved drastically.",
        avatar: "images/person6.webp",
        name: "Amina K.",
        role: "Engineering Manager, Consultant",
    },
    {
        id: 7,
        quote:
            "I’d been consulting for years, but DrillUp finally showed me how to integrate seamlessly without skipping a beat.",
        avatar: "images/person7.webp",
        name: "Anika P.",
        role: "Full-Stack Developer, Contractor",
    },
    {
        id: 8,
        quote:
            "Their ‘accelerate success in product teams’ motto isn’t just talk. My onboarding was smooth and impactful.",
        avatar: "images/person8.webp",
        name: "Miguel R.",
        role: "Product Owner, Full-Time",
    },
    {
        id: 9,
        quote:
            "Communication issues used to slow me down. After DrillUp’s sessions, I’ve mastered conflict resolution in any environment.",
        avatar: "images/person9.webp",
        name: "Arjun B.",
        role: "Technical Lead, Consultant",
    },
    {
        id: 10,
        quote:
            "From handling client expectations to thriving in daily scrums, DrillUp made me unstoppable as a contractor.",
        avatar: "images/person10.webp",
        name: "Maya N.",
        role: "UX Designer, Contractor",
    },
    {
        id: 11,
        quote:
            "I was skeptical, but their approach to soft skills changed everything. My new team instantly noticed my improved empathy.",
        avatar: "images/person11.webp",
        name: "Jamal E.",
        role: "DevOps Engineer, Full-Time",
    },
    {
        id: 12,
        quote:
            "As a consultant joining a distributed product team, DrillUp’s conflict management tips saved me months of headaches!",
        avatar: "images/person12.webp",
        name: "Hassan A.",
        role: "Cloud Architect, Consultant",
    },
    {
        id: 13,
        quote:
            "They helped me position myself better in a cross-functional environment, bridging cultural gaps in the process.",
        avatar: "images/person13.webp",
        name: "Samuel O.",
        role: "Backend Developer, Full-Time",
    },
    {
        id: 14,
        quote:
            "DrillUp’s real-world scenarios prepped me for any challenge. The focus on empathetic leadership was a game-changer.",
        avatar: "images/person14.webp",
        name: "Olivia J.",
        role: "QA Engineer, Consultant",
    },
    {
        id: 15,
        quote:
            "We’re a startup with contractors and employees, and DrillUp’s coaching unified us under a single product vision.",
        avatar: "images/person15.webp",
        name: "David F.",
        role: "CTO, Full-Time",
    },
    {
        id: 16,
        quote:
            "After their workshops, I became more assertive yet respectful. My contract was extended twice thanks to my new skillset.",
        avatar: "images/person16.webp",
        name: "Bjorn Z.",
        role: "Mobile Developer, Contractor",
    },
    {
        id: 17,
        quote:
            "DrillUp truly lives up to their promise of ‘accelerate success and collaboration’—I saw results in just weeks.",
        avatar: "images/person17.webp",
        name: "Sophia K.",
        role: "Data Engineer, Full-Time",
    },
    {
        id: 18,
        quote:
            "I joined a new product team overseas. DrillUp’s cross-cultural insights turned what could’ve been chaos into smooth sailing.",
        avatar: "images/person18.webp",
        name: "Gabriella C.",
        role: "Machine Learning Engineer, Consultant",
    },
    {
        id: 19,
        quote:
            "Their conflict-resolution and soft-skills training helped me lead cross-border projects for the first time.",
        avatar: "images/person19.webp",
        name: "Joshua L.",
        role: "Tech Lead, Full-Time",
    },
    {
        id: 20,
        quote:
            "I’ve never integrated into a product team faster. DrillUp’s approach is the secret sauce for any new hire—contract or full-time.",
        avatar: "images/person20.webp",
        name: "Emily B.",
        role: "Software Engineer, Contractor",
    },
    {
        id: 21,
        quote:
            "This coaching is inclusive of all roles and backgrounds. My diverse team benefited immensely from their empathy-centric training.",
        avatar: "images/person21.webp",
        name: "Emma S.",
        role: "Scrum Master, Full-Time",
    },
    {
        id: 22,
        quote:
            "Since adopting DrillUp’s best practices, my product team has collaborated more seamlessly than I ever imagined.",
        avatar: "images/person22.webp",
        name: "Fiona G.",
        role: "Engineering Manager, Full-Time",
    },
    {
        id: 23,
        quote:
            "Whether you’re an employee or consultant, DrillUp has something for everyone—our synergy skyrocketed!",
        avatar: "images/person23.webp",
        name: "Lea M.",
        role: "Business Analyst, Consultant",
    },
    {
        id: 24,
        quote:
            "Their focus on empathy, onboarding, and real-world scenarios cut down my learning curve significantly.",
        avatar: "images/person24.webp",
        name: "John D.",
        role: "Full-Stack Developer, Contractor",
    },
];

const Testimonials = () => {
    const carouselRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (carouselRef.current) {
                carouselRef.current.scrollLeft += 350; // Adjust for speed & distance
            }
        }, 9000);

        return () => clearInterval(interval);
    }, []);

    return (
        <TestimonialSection>
            <Title>Success Stories</Title>
            <Subtitle>
                Discover how DrillUp empowers both full-time hires and consultants to
                excel in product teams—no matter your role or environment.
            </Subtitle>
            <Carousel ref={carouselRef}>
                {testimonials.map((testimonial) => (
                    <TestimonialCard key={testimonial.id}>
                        <Avatar src={testimonial.avatar} alt="Client Avatar" />
                        <Name>{testimonial.name}</Name>
                        {/* NEW: Lighter, smaller text for the role on second line */}
                        <Role>{testimonial.role}</Role>
                        <Quote>{testimonial.quote}</Quote>
                    </TestimonialCard>
                ))}
            </Carousel>
        </TestimonialSection>
    );
};

// Styled Components
const TestimonialSection = styled.section`
    background-color: #f8f9ff;
    padding: 60px 20px;
    text-align: center;

    @media (max-width: 768px) {
        padding: 40px 20px;
    }
`;

const Title = styled.h2`
    color: #009379;
    letter-spacing: -0.76px;
    margin-top: 20px;
    font: 700 38px/130% Poppins, sans-serif;

    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const Subtitle = styled.p`
    color: #2d2d2d;
    font: 400 20px/1.5 "Mulish", sans-serif;
    max-width: 800px;
    margin: 20px auto 50px;
    font-style: italic;

    @media (max-width: 991px) {
        font-size: 18px;
        margin-bottom: 40px;
    }
`;

const Carousel = styled.div`
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 20px;
    padding: 20px 0;
    margin: 40px 0;

    // Hide scrollbar in Chrome/Safari/Edge
    &::-webkit-scrollbar {
        display: none;
    }

    // Hide scrollbar in Firefox
    scrollbar-width: none;
`;

const TestimonialCard = styled.article`
    background-color: #fff;
    border: 1px solid rgba(229, 244, 242, 1);
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(51, 102, 255, 0.05);
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    min-width: 350px;
    max-width: 350px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex: 0 0 auto;
    justify-content: flex-start;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 15px 25px rgba(51, 102, 255, 0.1);
    }

    @media (max-width: 768px) {
        min-width: 280px;
        padding: 20px;
    }
`;

const Avatar = styled.img`
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;
    width: 56px;
    align-self: center;
`;

const Name = styled.p`
    font-weight: 600;
    font-size: 1rem;
    margin-top: 10px;
    color: #2d2d2d;
`;

/* NEW: Slightly lighter, smaller text for the role/title */
const Role = styled.span`
    display: block;
    margin-top: 3px;
    font-weight: 400;
    font-size: 0.95rem;
    color: #555; /* or a lighter shade that suits your brand */
`;

const Quote = styled.blockquote`
  color: #2d2d2d;
  margin-top: 20px;
  font: 400 16px/24px Mulish, sans-serif;
  text-align: left;
  max-width: 300px;
  margin: 20px auto 0;
  position: relative;

  &::before {
    content: "“";
    position: absolute;
    left: -15px;
    font-size: 30px;
    color: #009379;
    top: -5px;
    font-weight: bold;
  }

  &::after {
    content: "”";
    position: absolute;
    right: -15px;
    font-size: 30px;
    color: #009379;
    bottom: -5px;
    font-weight: bold;
  }
`;

export default Testimonials;
