import React, { useState, useEffect, forwardRef } from "react";
import styled from "styled-components";
import Toaster from "./helpers/Toaster";
import { sendDataToLambda } from "./helpers/sendDataToLambda";
import { FaLinkedin } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import CookieSettingsModal from './CookieSettingsModal';

const socialMediaLinks = [
    {
        Icon: FaLinkedin,
        alt: 'LinkedIn',
        url: 'https://www.linkedin.com/company/drillup/',
    },
    {
        Icon: SiTiktok,
        alt: 'TikTok',
        url: 'https://tiktok.com/@drillup.tech',
    },
];

const emailType = {
    essential_success_package: "Essential Success Package Request",
    pro_advancement_package: "Pro Advancement Package Request",
    elite_career_package: "Elite Career Package Request",
    custom_request: "Custom Coaching Request",
    general_request: "General Request",
    feedback_or_support: "Feedback or Support",
};

const ContactSection = forwardRef(({ selectedPlan }, ref) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [questionType, setQuestionType] = useState(selectedPlan || "");
    const [message, setMessage] = useState("");
    const [charCount, setCharCount] = useState(0);
    const [showToaster, setShowToaster] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isCookieModalOpen, setIsCookieModalOpen] = useState(false);

    useEffect(() => {
        setQuestionType(selectedPlan);
        updateMessage(selectedPlan);
    }, [selectedPlan]);

    const updateMessage = (type) => {
        let messageText = "";
        switch (type) {
            case "essential_success_package":
                messageText =
                    "Hi there!\nI’d love to explore how the Essential Success Package could give me a solid jumpstart. Let’s talk soon!\nThanks!";
                break;
            case "pro_advancement_package":
                messageText =
                    "Hi there!\nI’m aiming to level up with the Pro Advancement Package. Can we chat about the steps to make that happen?\nThanks!";
                break;
            case "elite_career_package":
                messageText =
                    "Hi there!\nI’m excited to learn how the Elite Career Package can help me conquer bigger challenges. Let’s schedule a call!\nThanks!";
                break;
            case "custom_request":
                messageText =
                    "Hi there!\nI have some specific coaching needs and would like to discuss a custom approach. Looking forward to hearing from you!\nThanks!";
                break;
            case "general_request":
                messageText =
                    "Hi there!\nI’m curious about DrillUp’s services and want to learn more. Thanks in advance for any details you can share!\nThanks!";
                break;
            case "feedback_or_support":
                messageText =
                    "Hi there!\nI just wanted to say how much DrillUp’s guidance has boosted my confidence and career path. Thank you for all you do!\nThanks!";
                break;
            default:
                messageText = "";
        }
        setMessage(messageText);
        setCharCount(messageText.length);
    };

    const handleNameChange = (e) => setName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);

    const handleQuestionTypeChange = (e) => {
        const selectedType = e.target.value;
        setQuestionType(selectedType);
        handleContactFormClick(
            'request_type_change',
            'select_your_request',
            'contact_form',
            e.target.value
        );
        updateMessage(selectedType);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        handleContactFormClick(
            'text_message_change',
            'your_message',
            'contact_form',
            questionType,
            e.target.value
        );
        setCharCount(e.target.value.length);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        handleContactFormClick(
            'cta_click',
            'begin_your_journey_now',
            'contact_form',
            questionType,
            message
        );
        try {
            await sendDataToLambda({
                name,
                email,
                type: questionType,
                message,
                emailType: emailType[questionType],
            });
            setSubmitted(true);
            setShowToaster(true);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const handleContactFormClick = (
        eventCategory,
        label,
        componentName,
        requestType = '',
        message = ''
    ) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'contact_form_click', {
                event_category: eventCategory,
                event_label: label,
                component_name: componentName,
                request_type: requestType,
            });
        } else {
            console.warn('Google Analytics is not initialized');
        }
    };

    const handleSocialMediaClick = (eventCategory, label, componentName) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'social_media_click', {
                event_category: eventCategory,
                event_label: label,
                component_name: componentName,
            });
        } else {
            console.warn('Google Analytics is not initialized');
        }
    };

    const handleOpenCookiePreferences = (e) => {
        e.preventDefault();
        setIsCookieModalOpen(true);
    };

    return (
        <ContactContainer ref={ref}>
            <ContentWrapper>
                <Heading>Take the First Step</Heading>
                <Description>
                    <Paragraph>
                        Whether you’re ready to break into a new role, aiming for bigger responsibilities,
                        or just curious about how DrillUp can boost your professional journey, you’re in the right place.
                    </Paragraph>
                    <Paragraph>
                        Fill out this form and we’ll get back to you soon with tailored guidance and next steps.
                        No long wait times—just a clear, supportive path to reaching your goals faster.
                    </Paragraph>
                </Description>

                <ContactForm onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label htmlFor="name">
                            Your Name <Asterisk>*</Asterisk>
                        </Label>
                        <Input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                            required
                            placeholder="Enter your name"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="email">
                            Your Email <Asterisk>*</Asterisk>
                        </Label>
                        <Input
                            type="email"
                            id="email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            placeholder="Enter your email"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="questionType">
                            Select Your Request <Asterisk>*</Asterisk>
                        </Label>
                        <Select
                            id="questionType"
                            value={questionType}
                            onChange={handleQuestionTypeChange}
                            required
                        >
                            <option value="" disabled>
                                Select a question type
                            </option>
                            <option value="essential_success_package">
                                Essential Success Package Request
                            </option>
                            <option value="pro_advancement_package">
                                Pro Advancement Package Request
                            </option>
                            <option value="elite_career_package">
                                Elite Career Package Request
                            </option>
                            <option value="custom_request">Custom Coaching Request</option>
                            <option value="general_request">General Request</option>
                            <option value="feedback_or_support">Feedback or Support</option>
                        </Select>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="message">
                            Your Message <Asterisk>*</Asterisk>
                        </Label>
                        <Textarea
                            id="message"
                            value={message}
                            onChange={handleMessageChange}
                            required
                            minLength={20}
                            maxLength={500}
                        />
                        <CharCounter $isInvalid={charCount < 20}>
                            {charCount}/500
                        </CharCounter>
                    </FormGroup>

                    <SubmitButton type="submit">
                        {submitted ? "Thank You! We'll Be in Touch" : "Begin Your Journey Now"}
                    </SubmitButton>

                    <Toaster
                        message="Your Form Has Been Successfully Submitted!"
                        show={showToaster}
                        onClose={() => {
                            setShowToaster(false);
                            setEmail('');
                            setName('');
                            setQuestionType('pro_advancement_package');
                            setCharCount(102);
                            setMessage(
                                "Hi there!\nI’m aiming to level up with the Pro Advancement Package. Can we chat about the steps to make that happen?\nThanks!"
                            );
                            setSubmitted(false);
                        }}
                    />
                </ContactForm>

                <SocialMediaHeading>Stay in Touch</SocialMediaHeading>
                <SocialMediaIcons>
                    {socialMediaLinks.map((link, index) => (
                        <SocialMediaIconWrapper
                            key={index}
                            onClick={() => {
                                handleSocialMediaClick('link_click', link.alt, 'contact_form');
                                window.open(link.url, '_blank');
                            }}
                        >
                            <link.Icon
                                style={{
                                    color: '#ffffff',
                                    fontSize: '24px',
                                }}
                                aria-label={link.alt}
                            />
                        </SocialMediaIconWrapper>
                    ))}
                </SocialMediaIcons>

                <FooterLinks>
                    <FooterLink href="/privacy-policy.html" target="_blank">
                        Privacy Policy
                    </FooterLink>
                    <FooterLink href="/terms-of-service.html" target="_blank">
                        Terms of Service
                    </FooterLink>
                    <FooterLink href="/cookie-policy.html" aria-label="Cookies Policy" target="_blank">
                        Cookies Policy
                    </FooterLink>
                    <FooterLink href="#" onClick={handleOpenCookiePreferences}>
                        Cookies Preferences
                    </FooterLink>
                </FooterLinks>

                <Copyright>
                    © 2024 DrillUp.
                </Copyright>
            </ContentWrapper>

            {isCookieModalOpen && (
                <CookieSettingsModal
                    isOpen={isCookieModalOpen}
                    onClose={() => setIsCookieModalOpen(false)}
                />
            )}
        </ContactContainer>
    );
});

export default ContactSection;

/* --- Styled Components (Unchanged) --- */
const ContactContainer = styled.section`
    background-color: #fff;
    padding: 80px 40px 40px 40px;
    border-radius: 20px;
    border: 1px solid rgba(229, 244, 242, 1);
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    @media (max-width: 991px) {
        padding: 40px 20px;
    }
`;

const ContentWrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
`;

const Heading = styled.h2`
    color: #009379;
    font: 700 36px/1.3 Poppins, sans-serif;
    margin-bottom: 24px;
`;

const Description = styled.div`
    color: #2d2d2d;
    font: 400 20px/1.5 'Mulish', sans-serif;
    margin: 20px auto 50px;
    max-width: 800px;
    font-style: italic;
    @media (max-width: 991px) {
        font-size: 18px;
        margin-bottom: 40px;
    }
`;

const Paragraph = styled.p`
    margin-bottom: 16px;
`;

const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
    text-align: left;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    font: 500 16px/1.3 Poppins, sans-serif;
    color: #2d2d2d;
    margin-bottom: 8px;
`;

const Asterisk = styled.span`
    color: red;
`;

const Input = styled.input`
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font: 400 16px/1.3 Poppins, sans-serif;
    color: #333;
    outline: none;

    &:focus {
        border-color: #009379;
    }
`;

const Select = styled.select`
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font: 400 16px/1.3 Poppins, sans-serif;
    color: #333;
    outline: none;

    &:focus {
        border-color: #009379;
    }
`;

const Textarea = styled.textarea`
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font: 400 16px/1.3 Poppins, sans-serif;
    color: #333;
    outline: none;
    resize: vertical;
    min-height: 150px;

    &:focus {
        border-color: #009379;
    }
`;

const CharCounter = styled.div`
    font-size: 14px;
    text-align: right;
    color: ${(props) => (props.$isInvalid ? 'red' : '#555')};
    margin-top: 5px;
`;

const SubmitButton = styled.button`
    background-color: #009379;
    color: #fff;
    padding: 14px 20px;
    border: none;
    border-radius: 8px;
    font: 600 16px/1.3 Poppins, sans-serif;
    cursor: pointer;
    text-align: center;
    transition: transform 0.2s ease, box-shadow 0.3s ease, background-color 0.3s ease;

    &:hover {
        transform: translateY(-3px);
        background-color: #007c66;
        box-shadow: 0 15px 25px rgba(51, 102, 255, 0.1);
    }
`;

const SocialMediaHeading = styled.h3`
    color: #2d2d2d;
    font: 600 24px/1.3 Poppins, sans-serif;
    margin-bottom: 30px;
`;

const SocialMediaIcons = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
`;

const SocialMediaIconWrapper = styled.div`
    background-color: #009379;
    padding: 15px;
    border-radius: 50%;
    margin: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }
`;

const FooterLinks = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
`;

const FooterLink = styled.a`
    color: #009379;
    font: 400 14px/1.5 Poppins, sans-serif;
    text-decoration: none;
    margin: 5px;

    &:hover {
        text-decoration: underline;
    }
`;

const Copyright = styled.p`
    margin-top: 20px;
    color: #888;
    font: 400 14px/1.5 Poppins, sans-serif;
    text-align: center;
`;
