import React, {useRef, useEffect, forwardRef} from "react";
import styled from "styled-components";

const PRIMARY_COLOR = "#009379";

// 'Why DrillUp?' content
const whyDrillUpItems = [
    {
        title: "Empathy at the Core",
        description:
            "We prioritize genuine understanding. Our coaching develops emotional intelligence, enabling tech professionals—whether employees or consultants—to blend seamlessly into product teams.",
    },
    {
        title: "Proven Best Practices",
        description:
            "Through our experience with top tech companies across Europe, we’ve curated what works best. Our training addresses real-world scenarios, saving you from common integration pitfalls.",
    },
    {
        title: "Adaptable to Your Goals",
        description:
            "Short-term, long-term, mid-level, or senior—our flexible packages cater to every duration and seniority level, ensuring every professional gets the support they need.",
    },
    {
        title: "Specialized for Tech Roles",
        description:
            "From software engineers and data scientists to architects and engineering managers, our methods are tailored to help diverse roles flourish in product-oriented environments.",
    },
];

// 'Who We Serve' content
const whoWeServeItems = [
    {
        title: "Tech Professionals & Engineers",
        description:
            "Seeking smooth transitions, promotions, or upskilling for current roles.",
    },
    {
        title: "Consultants & Contractors",
        description:
            "Aiming to quickly integrate, collaborate effectively, and deliver value to clients.",
    },
    {
        title: "Product Companies",
        description:
            "Wanting external support to onboard and develop new talent—both permanent and contracted hires.",
    },
    {
        title: "Staffing & Consulting Agencies",
        description:
            "Looking to boost their consultants’ success rate and client satisfaction through robust soft-skill and cultural coaching.",
    },
];

const About = forwardRef(
    ({ selectedPlan, setSelectedPlan, scrollToContact }, ref) => {
    // Refs for each of the 4 cards in the left column
    const leftRefs = useRef([]);
    // Refs for each of the 4 cards in the right column
    const rightRefs = useRef([]);

    useEffect(() => {
        // Function to match heights for each row (index)
        const matchCardHeights = () => {
            // For safety, ensure arrays have the same length
            const length = Math.min(leftRefs.current.length, rightRefs.current.length);

            for (let i = 0; i < length; i++) {
                const leftEl = leftRefs.current[i];
                const rightEl = rightRefs.current[i];

                // Reset any previously set minHeights so measuring is accurate
                if (leftEl) leftEl.style.minHeight = "auto";
                if (rightEl) rightEl.style.minHeight = "auto";

                // Now measure & set the max
                const leftHeight = leftEl?.offsetHeight || 0;
                const rightHeight = rightEl?.offsetHeight || 0;
                const max = Math.max(leftHeight, rightHeight);

                if (leftEl) leftEl.style.minHeight = `${max}px`;
                if (rightEl) rightEl.style.minHeight = `${max}px`;
            }
        };

        // Run once on mount, and on window resize
        matchCardHeights();
        window.addEventListener("resize", matchCardHeights);
        return () => {
            window.removeEventListener("resize", matchCardHeights);
        };
    }, []);

    return (
        <AboutWrapper ref={ref}>
            {/* HERO / INTRO SECTION */}
            <HeroSection>
                <HeroContainer>
                    <HeroTitle>About DrillUp</HeroTitle>
                    <HeroSubtitle>
                        Elevate your career in product teams—whether you’re a full-time
                        employee, a consultant, or an entire organization seeking to onboard
                        new talent effectively.
                    </HeroSubtitle>
                </HeroContainer>
            </HeroSection>

            {/* MAIN CONTENT: 2 columns on desktop, stacked on mobile */}
            <MainContent>
                <ColumnsRow>
                    {/* LEFT COLUMN: "Why DrillUp?" */}
                    <Column>
                        <SectionTitle>Why DrillUp?</SectionTitle>
                        <SectionDescription>
                            We’re all about building empathy, delivering real-world solutions,
                            and tailoring our coaching to your specific role and career goals.
                        </SectionDescription>

                        {whyDrillUpItems.map((item, i) => (
                            <Card
                                key={`why-${i}`}
                                ref={(el) => (leftRefs.current[i] = el)} // Attach ref
                            >
                                <CardTitle>{item.title}</CardTitle>
                                <CardDescription>{item.description}</CardDescription>
                            </Card>
                        ))}
                    </Column>

                    {/* RIGHT COLUMN: "Who We Serve" */}
                    <Column>
                        <SectionTitle>Who We Serve</SectionTitle>
                        <SectionDescription>
                            DrillUp provides comprehensive support to various tech
                            professionals and organizations.
                        </SectionDescription>

                        {whoWeServeItems.map((item, i) => (
                            <Card
                                key={`serve-${i}`}
                                ref={(el) => (rightRefs.current[i] = el)} // Attach ref
                            >
                                <CardTitle>{item.title}</CardTitle>
                                <CardDescription>{item.description}</CardDescription>
                            </Card>
                        ))}
                    </Column>
                </ColumnsRow>
            </MainContent>
        </AboutWrapper>
    );
});

/* STYLED COMPONENTS */

const AboutWrapper = styled.div`
    background-color: #f8f9ff;
    width: 100%;
    overflow: hidden;
`;

const HeroSection = styled.section`
    background: #f8f9ff;
    padding: 60px 20px;
    text-align: center;
`;

const HeroContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
`;

const HeroTitle = styled.h1`
    font-family: Poppins, sans-serif;
    font-size: 2.8rem;
    font-weight: 700;
    color: ${PRIMARY_COLOR};
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 2.3rem;
    }
`;

const HeroSubtitle = styled.p`
    color: #2d2d2d;
    font: 400 20px/1.5 "Mulish", sans-serif;
    max-width: 700px;
    margin: 0 auto;
    line-height: 1.6;
    font-style: italic;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const MainContent = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
`;

const ColumnsRow = styled.div`
    display: flex;
    gap: 40px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Column = styled.div`
    flex: 1;
`;

const SectionTitle = styled.h2`
    color: ${PRIMARY_COLOR};
    font-size: 2rem;
    font-weight: 700;
    font-family: Poppins, sans-serif;
    margin-bottom: 10px;
`;

const SectionDescription = styled.p`
    color: #2d2d2d;
    font: 400 18px/1.5 "Mulish", sans-serif;
    margin-bottom: 20px;
    max-width: 600px;
    font-style: italic;
    line-height: 1.6;
`;

const Card = styled.div`
    background-color: #fff;
    border: 1px solid rgba(229, 244, 242, 1);
    border-radius: 15px;
    box-shadow: 0 6px 16px rgba(51, 102, 255, 0.05);
    padding: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;

    &:hover {
        transform: translateY(-6px);
        box-shadow: 0 15px 25px rgba(51, 102, 255, 0.1);
    }
`;

const CardTitle = styled.h3`
    color: ${PRIMARY_COLOR};
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    font-weight: 600;
`;

const CardDescription = styled.p`
    color: #2d2d2d;
    font-size: 0.95rem;
    line-height: 1.5;
    font-family: Mulish, sans-serif;
`;

export default About;
