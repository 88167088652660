import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const faqs = [
    {
        question: "What Differentiates DrillUp’s Coaching for Both Employees and Consultants?",
        answer: `
We’re not just about tips and generic advice—we aim to **reshape** how you integrate and succeed in product teams. Our empathy-driven method ensures you feel supported, while proven tactics accelerate your recognition and growth. Whether you’re a short-term consultant looking to secure contract extensions or a full-time hire aiming for that next promotion, our coaching approach adapts to you. The result? A smoother team fit, faster wins, and more doors opening on your career path.
`,
        label: "faq_diff_employees_consultants",
    },
    {
        question: "How Does DrillUp Adapt Training for Diverse Roles (Engineers, Data Scientists, PMs, etc.)?",
        answer: `
Each session is tailored to your specific role and environment. We focus on the unique challenges you face—be it juggling large data sets, bridging communication gaps as a PM, or navigating cross-functional collaboration. By zeroing in on what truly matters in **your** position, you’ll feel confident stepping into new responsibilities and demonstrating real impact—rather than getting stuck on the sidelines.
`,
        label: "faq_adapt_for_diverse_roles",
    },
    {
        question: "Why Is Empathy-Driven Soft-Skill Development So Essential?",
        answer: `
Technical prowess alone can’t guarantee you’ll stand out in a rapidly evolving product team. Empathy-driven coaching equips you with collaboration techniques, conflict resolution skills, and cultural awareness to unify your team and keep momentum high. This holistic approach often sets you apart as the go-to person for leadership or critical tasks—rather than someone whose potential goes unnoticed.
`,
        label: "faq_why_empathy_driven_important",
    },
    {
        question: "How Do You Ensure Both Consultants and Full-Time Employees Thrive Equally?",
        answer: `
We blend real-world tech experience (contract and permanent) with insights into diverse organizational cultures. Our sessions give you the **strategic edge** to blend seamlessly and build trust, regardless of contract length or position. When you adapt quickly—whether in a short-term project or a long-term role—you’re seen as an essential contributor, increasing your chances for contract renewals or internal promotions.
`,
        label: "faq_ensure_consultants_and_employees_thrive",
    },
    {
        question: "How Does DrillUp Tackle Product-Specific Challenges?",
        answer: `
First, we dive into your product’s domain, workflows, and tech stack. Then, we coach you on precisely where to focus for maximum impact—saving you from time-consuming missteps and trial-and-error. Instead of feeling overlooked or out of the loop, you can become a strategic player who understands the product inside and out, earning faster recognition and high-value project assignments.
`,
        label: "faq_product_specific_challenges",
    },
    {
        question: "What About Cultural and Communication Barriers in Global Teams?",
        answer: `
We’ve seen cultural nuances derail even the most brilliant professionals. Our targeted coaching helps you navigate different work styles, etiquette, and decision-making approaches so you blend right in—and even become the unifying force your team needs. This ability to harmonize across cultures often translates into stronger client relationships, smoother teamwork, and more leadership opportunities.
`,
        label: "faq_cultural_barriers_global_teams",
    },
    {
        question: "Why Should I Care About Soft-Skill ‘Mastery’ if My Tech Skills Are Strong?",
        answer: `
A track record of pure technical skill may not automatically lead to promotion or renewed contracts. Companies reward those who combine technical depth with clear communication and a collaborative mindset. When you show you can handle both the people aspect and the code, you’re far more likely to be picked for team leadership, special projects, and the professional growth that comes with them.
`,
        label: "faq_why_care_soft_skill_mastery",
    },
    {
        question: "How Quickly Can I See Results from DrillUp’s Coaching?",
        answer: `
It often depends on how wholeheartedly you embrace the strategies we provide. Many professionals notice tangible differences—like smoother onboarding, sharper communication, and a boost in overall performance—within weeks. The sooner you start applying new insights, the faster you can stand out and capitalize on the next wave of opportunities or challenges your organization sends your way.
`,
        label: "faq_how_fast_results",
    },
    {
        question: "Does DrillUp Provide Ongoing Support or One-Off Sessions?",
        answer: `
We do both, because we understand that every career path is unique. If you have a single challenge—like prepping for a big interview—you can book a focused session. If you’re aiming for continuous growth or adapting to a complex environment over time, an ongoing plan ensures you’re never without guidance. This flexibility helps you maintain consistent progress, rather than slipping back into old habits.
`,
        label: "faq_ongoing_support_or_one_off",
    },
    {
        question: "Is Cross-Cultural Training Really That Critical?",
        answer: `
In global product teams, small misunderstandings can create bigger ripples than you might expect. Our cross-cultural training ensures you don’t inadvertently undermine trust or credibility with teams from different regions. Instead, you become the person who bridges gaps and fosters a truly inclusive dynamic—raising your profile as someone capable of leading diverse teams effectively.
`,
        label: "faq_is_cross_cultural_critical",
    },
    {
        question: "Will I Have Access to Leadership or Career Path Consultations?",
        answer: `
Absolutely. Whether you’re an ambitious consultant looking to secure your next contract at a higher rate, or a full-time employee poised for a managerial role, we offer deeper career consultations. Together, we map out your trajectory—focusing on the leadership qualities and strategic thinking that can fast-track your advancement and open up options you hadn’t even considered.
`,
        label: "faq_leadership_career_path_consults",
    },
    {
        question: "How Exactly Do I Start?",
        answer: `
It’s straightforward.  
1. **Reach Out:** Book a brief initial conversation—no obligation, just clarity on your goals.  
2. **We Propose a Tailored Plan:** Based on your background and objectives.  
3. **Kick Off Your Sessions:** Dive into skill-building, soft-skill exercises, or deep cultural integration coaching.  
4. **Reap the Rewards:** Solidify your place in the team, speed up your learning curve, and elevate your professional brand.

Waiting too long could mean missed chances or stalled growth. Starting now sets you on the path to greater impact—sooner.
`,
        label: "faq_how_exactly_start",
    },
];

const FAQSection = forwardRef((props, ref) => {
    const [expandedFAQ, setExpandedFAQ] = useState(null);

    const handleFAQClick = (label, index) => {
        // Google Analytics event (if any)
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'faq_question_click', {
                event_category: 'faq_interaction',
                event_label: label,
                component_name: 'faq_section',
            });
        } else {
            console.warn('Google Analytics is not initialized');
        }

        // Toggle the expanded FAQ
        setExpandedFAQ(expandedFAQ === index ? null : index);
    };

    return (
        <FAQSectionWrapper ref={ref}>
            <SectionTitle>FAQ</SectionTitle>
            <FAQList>
                {faqs.map((faq, index) => {
                    const htmlAnswer = DOMPurify.sanitize(marked.parse(faq.answer));
                    return (
                        <FAQItem key={index}>
                            <Question onClick={() => handleFAQClick(faq.label, index)}>
                                {faq.question}
                                <ToggleIcon $isExpanded={expandedFAQ === index}>
                                    <FaChevronDown />
                                </ToggleIcon>
                            </Question>
                            {expandedFAQ === index && (
                                <>
                                    <Separator />
                                    <Answer>
                                        <AnswerContent
                                            dangerouslySetInnerHTML={{ __html: htmlAnswer }}
                                        />
                                    </Answer>
                                </>
                            )}
                        </FAQItem>
                    );
                })}
            </FAQList>
        </FAQSectionWrapper>
    );
});

export default FAQSection;

/* --- STYLED COMPONENTS (unchanged) --- */
const FAQSectionWrapper = styled.section`
    background-color: #fcfcfe;
    padding: 80px 20px;
    text-align: center;
    @media (max-width: 991px) {
        padding: 60px 20px;
    }
`;

const SectionTitle = styled.h2`
    color: #009379;
    font: 700 38px/1.3 'Poppins', sans-serif;
    letter-spacing: -0.76px;
    margin: 20px 0 50px;
    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const FAQList = styled.div`
    max-width: 900px;
    margin: 0 auto;
    text-align: left;
`;

const FAQItem = styled.div`
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    transition: all 0.3s ease;
`;

const Question = styled.button`
    background-color: #fff;
    color: #2d2d2d;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font: 600 22px/1.5 'Poppins', sans-serif;
    padding: 20px 30px;
    border: none;
    outline: none;
    text-align: left;
    &:hover {
        background-color: #f0faf8;
    }
`;

const ToggleIcon = styled.span`
    font-size: 20px;
    color: #009379;
    transition: transform 0.3s ease;
    transform: ${(props) =>
            props.$isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const Separator = styled.div`
    height: 1px;
    background-color: #e0e0e0;
    margin: 0 30px;
`;

const Answer = styled.div`
    background-color: #fafbfc;
`;

const AnswerContent = styled.div`
    color: #555;
    font: 400 18px/1.8 'Mulish', sans-serif;
    padding: 25px 30px;
    margin: 0;
    h3,
    h4 {
        font-weight: 600;
        color: #2d2d2d;
        margin-top: 20px;
    }
    p {
        margin-bottom: 15px;
    }
    ul,
    ol {
        padding-left: 20px;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    li {
        margin-bottom: 8px;
        line-height: 1.6;
    }
    strong,
    b {
        font-weight: 600;
        color: #2d2d2d;
    }
    a {
        color: #009379;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;
