import React, { useState, useRef } from "react";
import './App.css';

import Header from './components/Header';
import LandingPage from './components/LandingPage';
import RealStories from "./components/RealStories";
import Features from "./components/Features";
import PricingSection from "./components/PricingSection";
import ContactSection from "./components/ContactSection";
import FAQSection from "./components/FAQSection";
import CookieConsent from './components/CookieConsent'; // Import the component

function App() {
    const isLaunched = true;
    const pricingRef = useRef(null);
    const contactRef = useRef(null);
    const featuresRef = useRef(null);
    const [selectedPlan, setSelectedPlan] = useState('pro_advancement_package');

    const scrollToPricing = (event) => {
        event.preventDefault();
        const offset = 80;
        const topPosition = pricingRef.current.getBoundingClientRect().top + window.pageYOffset + offset;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
    };

    const scrollToContact = (event) => {
        event.preventDefault();
        const offset = 85;
        const topPosition = contactRef.current.getBoundingClientRect().top + window.pageYOffset + offset;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
    };

    const scrollToFeatures = (event) => {
        event.preventDefault();
        const offset = 0;
        const topPosition = featuresRef.current.getBoundingClientRect().top + window.pageYOffset + offset;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
    };

    return (
        isLaunched ? (
            <div className="App">
                <Header
                    scrollToPricing={scrollToPricing}
                    scrollToContact={scrollToContact}
                    scrollToFeatures={scrollToFeatures}
                />
                <LandingPage
                    scrollToPricing={scrollToPricing}
                    scrollToContact={scrollToContact}
                />
                <Features ref={featuresRef} />
                <RealStories />
                <PricingSection
                    ref={pricingRef}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                    scrollToContact={scrollToContact}
                />
                <FAQSection />
                <ContactSection
                    ref={contactRef}
                    selectedPlan={selectedPlan}
                />
                <CookieConsent /> {/* Add CookieConsent component */}
            </div>
        ) : (
            <div className="App"> Work in Progress </div>
        )
    );
}

export default App;
