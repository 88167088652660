import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Toaster from "./helpers/Toaster";
import { sendDataToLambda } from "./helpers/sendDataToLambda";

const landingPageData = {
    title: "Empower Tech Professionals to Thrive in Product Teams",
    description:
        "From full-time engineers to contracted consultants, DrillUp ensures faster onboarding, cross-cultural collaboration, and soft-skill mastery so you can succeed in any environment.",
    ctaText: "Book a Free Consultation",
    ctaLink: "#",
    secondaryCtaText: "Start Your Journey",
    secondaryCtaLink: "#",
};

const emailType = {
    access_to_slack: "Access to Slack Community",
    personalized_interview_prep: "Personalized Interview Prep",
    career_advancement_coaching: "Career Advancement Coaching",
    end_to_end_career_guidance: "End-to-End Career Guidance",
};

const LandingPage = ({ scrollToPricing, scrollToContact }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [sessionType, setSessionType] = useState("access_to_slack");
    const [submitted, setSubmitted] = useState(false);
    const [showToaster, setShowToaster] = useState(false);

    const handleCTAClick = (label, componentName, selectedCoachingType = "") => {
        if (typeof window.gtag === "function") {
            window.gtag("event", "cta_click", {
                event_category: "landing",
                event_label: label,
                component_name: componentName,
                coaching_type: selectedCoachingType,
            });
        } else {
            console.warn("Google Analytics is not initialized");
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        handleCTAClick("book_your_free_consultation", "small_form", sessionType);
        try {
            await sendDataToLambda({
                name,
                email,
                type: sessionType,
                emailType: emailType[sessionType],
            });
            setSubmitted(true);
            setShowToaster(true);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <LandingPageWrapper>
            {/* HERO SECTION */}
            <HeroContainer>
                <HeroContent>
                    <HeroTitle>{landingPageData.title}</HeroTitle>
                    <HeroDescription>{landingPageData.description}</HeroDescription>
                    <HeroCTAWrapper>
                        <HeroCTA
                            href={landingPageData.ctaLink}
                            onClick={(event) => {
                                scrollToContact(event);
                                handleCTAClick("book_free_consultation", "landing")
                            }}
                        >
                            {landingPageData.ctaText}
                        </HeroCTA>

                        {landingPageData.secondaryCtaText && (
                            <HeroSecondaryCTA
                                href={landingPageData.secondaryCtaLink}
                                onClick={(event) => {
                                    scrollToPricing(event);
                                    handleCTAClick("start_your_journey", "landing");
                                }}
                            >
                                {landingPageData.secondaryCtaText}
                            </HeroSecondaryCTA>
                        )}
                    </HeroCTAWrapper>
                </HeroContent>
            </HeroContainer>

            {/* GRID SECTION: BENEFITS (Left) + FORM (Right) */}
            <GridSection>
                <GridContainer>
                    {/* LEFT: BENEFITS */}
                    <BenefitsWrapper>
                        <BenefitsTitle>Key Benefits</BenefitsTitle>
                        <BenefitsGrid>
                            <BenefitCard>
                                <BenefitTitle>Accelerated Onboarding</BenefitTitle>
                                <BenefitDescription>
                                    Reduce friction during the first weeks or months in a new team.
                                </BenefitDescription>
                            </BenefitCard>

                            <BenefitCard>
                                <BenefitTitle>Cultural & Communication Mastery</BenefitTitle>
                                <BenefitDescription>
                                    Overcome cultural and organizational barriers to collaboration.
                                </BenefitDescription>
                            </BenefitCard>

                            <BenefitCard>
                                <BenefitTitle>Soft-Skill Excellence</BenefitTitle>
                                <BenefitDescription>
                                    Learn empathy-driven collaboration, conflict resolution, and leadership essentials.
                                </BenefitDescription>
                            </BenefitCard>

                            <BenefitCard>
                                <BenefitTitle>Flexible Support</BenefitTitle>
                                <BenefitDescription>
                                    Receive one-time consultations or continuous coaching—whichever suits your needs.
                                </BenefitDescription>
                            </BenefitCard>
                        </BenefitsGrid>
                    </BenefitsWrapper>

                    {/* RIGHT: FORM */}
                    <FormContainer onSubmit={handleFormSubmit}>
                        <FormTitle>Book Your Free Consultation</FormTitle>
                        <FormGroup>
                            <Label htmlFor="name">
                                Your Name <Asterisk>*</Asterisk>
                            </Label>
                            <Input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter your name"
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="email">
                                Your Email <Asterisk>*</Asterisk>
                            </Label>
                            <Input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                required
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="sessionType">
                                Type of Coaching <Asterisk>*</Asterisk>
                            </Label>
                            <Select
                                id="sessionType"
                                value={sessionType}
                                onChange={(e) => setSessionType(e.target.value)}
                                required
                            >
                                <option value="access_to_slack">Access to Slack Community</option>
                                <option value="personalized_interview_prep">Personalized Interview Prep</option>
                                <option value="career_advancement_coaching">Career Advancement Coaching</option>
                                <option value="end_to_end_career_guidance">End-to-End Career Guidance</option>
                            </Select>
                        </FormGroup>

                        <SubmitButton type="submit">
                            {submitted ? "Thank You! We'll be in Touch" : "Book Your Free Consultation"}
                        </SubmitButton>

                        <Toaster
                            message="Your Form Has Been Successfully Submitted!"
                            show={showToaster}
                            onClose={() => {
                                setShowToaster(false);
                                setEmail("");
                                setName("");
                                setSessionType("personalized_interview_prep");
                                setSubmitted(false);
                            }}
                        />
                    </FormContainer>
                </GridContainer>
            </GridSection>
        </LandingPageWrapper>
    );
};

/* =========== ANIMATIONS =========== */
const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

/* =========== PAGE WRAPPER =========== */
const LandingPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #f8f9ff;
    animation: ${fadeIn} 1s ease-out;
    overflow-x: hidden;
`;

/* =========== HERO SECTION =========== */
const HeroContainer = styled.section`
    width: 100%;
    padding: 60px 20px;
    text-align: center;
    box-sizing: border-box;
`;

const HeroContent = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;

const HeroTitle = styled.h1`
    color: #2d2d2d;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.2;

    @media (max-width: 768px) {
        font-size: 2.5rem;
    }
`;

const HeroDescription = styled.p`
    color: #2d2d2d;
    font: 400 20px/1.5 "Mulish", sans-serif;
    margin: 20px auto 30px;
    max-width: 800px;
    font-style: italic;
    line-height: 1.6;

    @media (max-width: 991px) {
        font-size: 18px;
    }
`;

const HeroCTAWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`;

const HeroCTA = styled.a`
    background-color: #009379;
    color: #fff;
    padding: 15px 30px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
        background-color: #007b64;
        transform: translateY(-3px);
    }
    @media (max-width: 768px) {
        padding: 12px 25px;
    }
`;

const HeroSecondaryCTA = styled.a`
    background-color: transparent;
    color: #009379;
    padding: 15px 30px;
    border-radius: 30px;
    border: 2px solid #009379;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;

    &:hover {
        background-color: #f1f1f1;
        color: #007b64;
        transform: translateY(-3px);
    }
    @media (max-width: 768px) {
        padding: 12px 25px;
    }
`;

/* =========== GRID SECTION =========== */
const GridSection = styled.section`
    width: 100%;
    background: #f8f9ff;
    padding: 60px 20px;
    box-sizing: border-box;

    @media (max-width: 991px) {
        padding: 40px 20px;
    }
`;

const GridContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    align-items: stretch; // Makes both columns same height

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        max-width: 600px;
        gap: 40px;
    }
`;

/* =========== BENEFITS (Left) =========== */
const BenefitsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    background: #fff;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    height: 100%;
    box-sizing: border-box;

    @media (max-width: 991px) {
        padding: 25px;
    }
`;

const BenefitsTitle = styled.h3`
    color: #2d2d2d;
    font-size: 1.75rem;
    font-weight: 600;
    margin: 0;
`;

const BenefitsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    flex: 1; // Takes up remaining space

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

const BenefitCard = styled.div`
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.07);
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &:hover {
        transform: translateY(-3px);
    }
`;

const BenefitTitle = styled.h4`
    font-size: 1.1rem;
    color: #009379;
    font-weight: 600;
    margin: 0;
`;

const BenefitDescription = styled.p`
    color: #2d2d2d;
    line-height: 1.4;
    margin: 0;
`;

/* =========== FORM (Right) =========== */
const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    box-sizing: border-box;
    height: 100%;

    @media (max-width: 991px) {
        padding: 25px;
        max-width: 100%;
    }
`;

const FormTitle = styled.h3`
    color: #2d2d2d;
    font-size: 1.75rem;
    font-weight: 600;
    margin: 0 0 10px 0;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Label = styled.label`
    font: 500 16px/1.3 Poppins, sans-serif;
    color: #2d2d2d;
    text-align: left;
    padding-right: 8px;

    @media (max-width: 768px) {
        text-align: left;
        padding-right: 0;
    }
`;

const Asterisk = styled.span`
    color: red;
`;

const Input = styled.input`
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font: 400 16px/1.3 Poppins, sans-serif;
    color: #333;
    outline: none;

    &:focus {
        border-color: #009379;
    }
`;

const Select = styled.select`
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font: 400 16px/1.3 Poppins, sans-serif;
    color: #333;
    outline: none;

    &:focus {
        border-color: #009379;
    }
`;

const SubmitButton = styled.button`
    background-color: ${({ disabled }) => (disabled ? "#ccc" : "#009379")};
    color: #fff;
    padding: 14px 0;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: auto; // Pushes button to bottom of form

    &:hover {
        background-color: ${({ disabled }) => (disabled ? "#ccc" : "#007b64")};
        transform: translateY(-3px);
    }
`;

export default LandingPage;
