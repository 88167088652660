import React, { useState, useEffect, forwardRef } from "react";
import styled, { keyframes } from "styled-components";

// Updated pricing plans data with fear/greed-driven messaging
const pricingPlans = [
    {
        name: "Essential Integration Package",
        value: "essential_integration_package",
        price: "€99",
        oldPrice: "€199",
        recommendedFor: "Consultants or engineers wanting a smooth, high-impact start",
        // Fear & Greed Messaging
        description: `New to a product team or consulting role? Don’t sabotage your future 
by stumbling through your first weeks. With the Essential Integration Package, 
you’ll impress colleagues and management right away—so you don’t get overlooked 
for upcoming opportunities.`,
        features: [
            "1 x 1-Hour Coaching Session",
            "5 Days of Chat Support for Follow-up Questions",
            "Access to Our Slack Community (networking & peer support)",
        ],
        buttonText: "Reserve Your Free Consultation",
        buttonStyle: "outline",
        discount: "50% OFF",
    },
    {
        name: "Pro Advancement Package",
        value: "pro_advancement_package",
        price: "€449",
        oldPrice: "€599",
        recommendedFor:
            "Mid-level pros eager to stand out and seize bigger opportunities",
        // Fear & Greed Messaging
        description: `Is your career stuck in mid-level limbo? Don’t let others leapfrog 
you when a promotion or salary bump could be within reach. The Pro Advancement 
Package gives you advanced soft skills and a compelling professional brand to 
outshine the competition and command higher roles—faster.`,
        features: [
            "3 x 1-Hour Coaching Sessions",
            "14 Days of Chat Support",
            "Comprehensive CV/Portfolio Review",
            "Cultural Integration & Communication Strategies",
            "Exclusive Slack Community Access",
        ],
        buttonText: "Reserve Your Free Consultation",
        buttonStyle: "outline",
        discount: "25% OFF",
    },
    {
        name: "Elite Career Package",
        value: "elite_career_package",
        price: "€899",
        oldPrice: "€1199",
        recommendedFor:
            "Senior experts, long-term consultants, or tech leads striving for top-tier leadership",
        // Fear & Greed Messaging
        description: `Feeling overshadowed or hitting a plateau? Don’t let younger, 
hungrier pros steal the spotlight. The Elite Career Package is your ticket to 
unshakable leadership presence and unstoppable growth—unlocking prestige, influence, 
and the massive gains that come with top-level success.`,
        features: [
            "10 x 1-Hour Coaching Sessions",
            "30 Days of Chat Support for Follow-up Questions",
            "Comprehensive Leadership & Career Path Consultations",
            "All Pro Advancement Features Included",
            "Access to an Exclusive Private Community of Top Industry Leaders",
        ],
        buttonText: "Reserve Your Free Consultation",
        buttonStyle: "outline",
        discount: "25% OFF",
    },
];

// Keyframe for subtle bounce animation when a plan is selected
const bounce = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
`;

const PricingSection = forwardRef(
    ({ selectedPlan, setSelectedPlan, scrollToContact }, ref) => {
        const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

        useEffect(() => {
            const timer = setInterval(() => {
                setTimeLeft(calculateTimeLeft());
            }, 1000);

            return () => clearInterval(timer);
        }, []);

        function calculateTimeLeft() {
            // Adjust the final date/time as needed
            const difference = +new Date("2025-01-20T23:59:59") - +new Date();
            let timeLeft = {};

            if (difference > 0) {
                timeLeft = {
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60),
                };
            } else {
                timeLeft = null;
            }
            return timeLeft;
        }

        const handlePricingPlanClick = (label, componentName, pricingType = "") => {
            if (typeof window.gtag === "function") {
                window.gtag("event", "pricing_plan_click", {
                    event_category: "pricing_plan",
                    event_label: label,
                    component_name: componentName,
                    pricing_plan: pricingType,
                });
            } else {
                console.warn("Google Analytics is not initialized");
            }
        };

        const handleCTAClick = (label, componentName, pricingType = "") => {
            if (typeof window.gtag === "function") {
                window.gtag("event", "cta_click", {
                    event_category: "pricing_plan",
                    event_label: label,
                    component_name: componentName,
                    coaching_type: pricingType,
                });
            } else {
                console.warn("Google Analytics is not initialized");
            }
        };

        const handleSelectPlan = (plan) => {
            setSelectedPlan(plan.value);
            handlePricingPlanClick("select_plan", "pricing_section", plan.value);
        };

        return (
            <PricingSectionWrapper ref={ref}>
                <SectionTitle>Choose Your Package</SectionTitle>

                {timeLeft ? (
                    <CountdownTimer>
                        <TimerText>⚡ Limited Time Offer Ends In:</TimerText>
                        <TimerValue>
                            {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m{" "}
                            {timeLeft.seconds}s
                        </TimerValue>
                    </CountdownTimer>
                ) : (
                    <CountdownTimer>
                        <TimerText>Offer has ended</TimerText>
                    </CountdownTimer>
                )}

                <SectionSubtitle>
                    Stop missing out on key opportunities—accelerate your success and
                    collaboration in product teams today.
                </SectionSubtitle>

                <PricingCardsContainer>
                    {pricingPlans.map((plan, index) => (
                        <PricingCard
                            key={index}
                            onClick={() => handleSelectPlan(plan)}
                            $isSelected={selectedPlan === plan.value}
                        >
                            {plan.discount && <DiscountBadge>{plan.discount}</DiscountBadge>}

                            <PlanName>{plan.name}</PlanName>

                            {/* “Recommended For …” line */}
                            <RecommendedFor>{plan.recommendedFor}</RecommendedFor>

                            <PriceContainer>
                                <OldPrice>{plan.oldPrice}</OldPrice>
                                <Price>{plan.price}</Price>
                            </PriceContainer>

                            <PlanDescription>{plan.description}</PlanDescription>

                            <FeaturesList>
                                {plan.features.map((feature, featureIndex) => (
                                    <FeatureItem key={featureIndex}>
                                        <FeatureBullet />
                                        <FeatureText>{feature}</FeatureText>
                                    </FeatureItem>
                                ))}
                            </FeaturesList>

                            <PlanButtonWrapper>
                                <PlanButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        scrollToContact(event);
                                        handleCTAClick(
                                            "select_plan",
                                            "pricing_section",
                                            plan.value
                                        );
                                    }}
                                    $buttonStyle={
                                        selectedPlan === plan.value ? "filled" : plan.buttonStyle
                                    }
                                >
                                    {plan.buttonText}
                                </PlanButton>
                            </PlanButtonWrapper>
                        </PricingCard>
                    ))}
                </PricingCardsContainer>
            </PricingSectionWrapper>
        );
    }
);

/* STYLED COMPONENTS */
const PricingSectionWrapper = styled.section`
    background-color: #f8f9ff;
    padding: 80px 20px;
    text-align: center;
    position: relative;

    @media (max-width: 991px) {
        padding: 60px 20px;
    }
`;

const SectionTitle = styled.h2`
    color: #009379;
    font: 700 38px/1.3 Poppins, sans-serif;
    letter-spacing: -0.76px;
    margin: 20px 0 10px;

    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const CountdownTimer = styled.div`
    background-color: #ff4d4f;
    color: #fff;
    padding: 12px 20px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    font: 600 16px/1.3 Poppins, sans-serif;
`;

const TimerText = styled.span`
    margin-right: 10px;
`;

const TimerValue = styled.span`
    font-weight: 700;
`;

const SectionSubtitle = styled.p`
    color: #2d2d2d;
    font: 400 20px/1.5 "Mulish", sans-serif;
    margin: 0 auto 30px;
    max-width: 800px;
    font-style: italic;

    @media (max-width: 991px) {
        font-size: 18px;
        margin-bottom: 20px;
    }
`;

const PricingCardsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 30px;

    @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
`;

const PricingCard = styled.div`
    background-color: #fff;
    border: ${(props) =>
            props.$isSelected ? "2px solid #009379" : "1px solid #e0e0e0"};
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(51, 102, 255, 0.05);
    color: #2d2d2d;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    width: 100%;
    max-width: 380px;
    text-align: left;
    transition: transform 0.3s ease-in-out;
    flex: 1;
    cursor: pointer;
    position: relative;

    /* subtle bounce animation if selected */
    animation: ${(props) =>
            props.$isSelected ? bounce : "none"} 0.4s cubic-bezier(0.25, 1, 0.5, 1);

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 15px 25px rgba(51, 102, 255, 0.1);
    }

    @media (max-width: 991px) {
        max-width: none;
        padding: 30px 20px;
        height: auto;
    }
`;

const DiscountBadge = styled.div`
    background-color: #ff4d4f;
    color: #fff;
    padding: 6px 12px;
    border-radius: 8px;
    position: absolute;
    top: 20px;
    right: 20px;
    font: 700 14px/1.3 Poppins, sans-serif;
`;

const PlanName = styled.h3`
    font: 600 24px/1.5 Poppins, sans-serif;
    margin-top: 40px;
`;

const RecommendedFor = styled.p`
    font: 400 14px/1.4 "Mulish", sans-serif;
    color: #777;
    margin-top: 5px;
    margin-bottom: 20px;
    font-style: italic;
`;

const PriceContainer = styled.div`
    display: flex;
    align-items: baseline;
    gap: 12px;
    margin-top: 12px;
`;

const OldPrice = styled.span`
    font: 500 24px/1.3 Poppins, sans-serif;
    color: #888;
    text-decoration: line-through;
`;

const Price = styled.span`
    font: 700 50px/1.3 Poppins, sans-serif;
    letter-spacing: -1px;
    color: #009379;
    @media (max-width: 991px) {
        font-size: 40px;
    }
`;

const PlanDescription = styled.p`
    color: #333;
    font: 500 15px/1.6 Poppins, sans-serif;
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 2px solid #e5f4f2;
`;

const FeaturesList = styled.ul`
    list-style: none;
    margin: 24px 0 30px;
    padding: 0;
    flex: 1;
`;

const FeatureItem = styled.li`
    display: flex;
    align-items: flex-start;
    color: #555;
    font: 400 15px/1.6 Mulish, sans-serif;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
`;

const FeatureBullet = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #009379;
    margin-top: 8px;
    margin-right: 12px;
`;

const FeatureText = styled.span`
    flex: 1;
`;

const PlanButtonWrapper = styled.div`
    margin-top: auto;
    display: flex;
    justify-content: center;
`;

const PlanButton = styled.button`
    background-color: ${(props) =>
            props.$buttonStyle === "filled" ? "#009379" : "#e5f4f2"};
    border: ${(props) =>
            props.$buttonStyle === "filled" ? "none" : "1px solid #009379"};
    border-radius: 20px;
    color: ${(props) => (props.$buttonStyle === "filled" ? "#fff" : "#009379")};
    cursor: pointer;
    font: 600 16px/1.5 Poppins, sans-serif;
    padding: 16px 50px;
    text-align: center;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${(props) =>
                props.$buttonStyle === "filled" ? "#007c66" : "#d4eeea"};
    }

    @media (max-width: 991px) {
        padding: 16px 30px;
    }
`;

export default PricingSection;
